<template>
  <vs-card>
    <div slot="header" class="w-full items-center flex justify-between">
      <h4 class="capitalize">
        Details
      </h4>
      <div class="flex items-center">
        <vs-button
          class=""
          icon="close"
          size="medium"
          @click="$emit('remove', index)"
        ></vs-button>
      </div>
    </div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('topic.label') && topic.label != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('topic.label') ? true : false"
            v-validate="'required'"
            name="label"
            data-vv-as="label"
            label="Label"
            class="w-full"
            data-vv-scope="topic"
            :danger-text="errors.first('topic.label')"
            v-model="topic.label"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('topic.slug') && topic.value != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('topic.slug') ? true : false"
            v-validate="'required'"
            name="slug"
            data-vv-as="slug"
            label="Slug"
            class="w-full"
            data-vv-scope="topic"
            :danger-text="errors.first('topic.slug')"
            v-model="topic.value"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <vs-input
            :success="!errors.first('topic.title') && topic.page.text != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('topic.title') ? true : false"
            v-validate="'required'"
            name="title"
            data-vv-as="title"
            label="Resources Section Title"
            class="w-full"
            data-vv-scope="topic"
            :danger-text="errors.first('topic.title')"
            v-model="topic.page.text"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="Header"
            ref="refHeader">
            <vs-textarea
              name="header"
              label="Header"
              class="w-full"
              v-model="topic.page.header"
            />
            <span class="text-red-600" v-show="errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full sm:w-1/2 m-5">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="sub header"
            ref="refSubheader">
            <vs-textarea
              name="subHeader"
              label="Sub Header"
              class="w-full"
              v-model="topic.page.subHeader"
            />
            <span class="text-red-600" v-show="errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="12"
        class="vs-con-loading__container flex flex-col">
        <div class="flex flex-col gap-3 items-start justify-between mx-5 w-full h-full">
          <label class="text-base font-semibold">
            Icon 120x120 - <span class="text-xs">Display in resource page</span>
            <span v-if="imageErrors[0].icon" class="text-red-600">
              *Icon is required
            </span>
          </label>
          <div v-if="topic.icon" class="flex flex-col items-start gap-2">
            <img :src="topic.icon" alt="Uploaded" />
            <vs-button line-origin="center" color="dark" type="line" class="px-0 py-1 ml-1" @click="handleRemoveImage('icon')">Remove Image</vs-button>
          </div>
          <div v-else id="icon" class="customHeight w-3/4 sm:w-1/2 text-center relative">
            <div
              class="border-2 border-dashed border-gray-500 p-5 
                h-full w-full absolute flex flex-col gap-3 
                items-center justify-center"
              @dragover.prevent
              @drop="handleDrop($event, 'icon')"
            >
              <feather-icon icon="UploadIcon" class="text-primary2" />
              <p class="text-primary2 text-xl">Drop files to upload</p>
              <p class="text-primary2 text-lg">or</p>
              <input type="file" ref="fileInputIcon" hidden @change="handleFileChange($event, 'icon')" accept="image/*" />
              <vs-button @click="clickUploadImage('fileInputIcon')" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
              <p class="text-lg font-light">Recommended Image Size</p>
              <p class="text-lg font-light">120 x 120</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-3 items-start justify-between m-5 w-full h-full">
          <label class="text-base font-semibold">
            Banner 625x375 - <span class="text-xs">Display in topic page</span>
            <span v-if="imageErrors[0].banner" class="text-red-600">
              *Banner is required
            </span>
          </label>
          <div v-if="topic.page.image" class="flex flex-col items-start gap-2">
            <img :src="topic.page.image" alt="Uploaded" />
            <vs-button line-origin="center" color="dark" type="line" class="px-0 py-1 ml-1" @click="handleRemoveImage('banner')">Remove Image</vs-button>
          </div>
          <div v-else id="banner" class="customHeight w-3/4 sm:w-1/2 text-center relative">
            <div
              class="border-2 border-dashed border-gray-500 p-5 
                h-full w-full absolute flex flex-col gap-3 
                items-center justify-center"
              @dragover.prevent
              @drop="handleDrop($event, 'banner')"
            >
              <feather-icon icon="UploadIcon" class="text-primary2" />
              <p class="text-primary2 text-xl">Drop files to upload</p>
              <p class="text-primary2 text-lg">or</p>
              <input type="file" ref="fileInputBanner" hidden @change="handleFileChange($event, 'banner')" accept="image/*" />
              <vs-button @click="clickUploadImage('fileInputBanner')" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
              <p class="text-lg font-light">Recommended Image Size</p>
              <p class="text-lg font-light">625 x 375</p>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-divider />
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <div class="w-full m-5">
        <vs-card>
          <div slot="header" class="w-full items-center flex justify-between">
            <h4 class="capitalize">
              Partners
            </h4>
            <div class="flex items-center">
              <vs-button class="mr-2" size="medium" @click="addPartner"
                >Add</vs-button
              >
            </div>
          </div>
          <div
            v-for="(partner, partnerIndex) in topics[index].page.partners"
            :key="partnerIndex"
          >
            <Partner
              :partner="partner"
              :topics="topics"
              :topicIndex="index"
              :index="partnerIndex"
              @remove="removePartner"
              ref="refPartner"
            />
          </div>
        </vs-card>
      </div>
    </vs-row>
  </vs-card>
</template>

<script>
import Partner from "./Partner.vue"
import axios from "@/axios";
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    Partner,
    ValidationProvider
  },
  props: ["topic", "index", "topics", "isDragging"],
  data() {
    return {
      imageType: "",
      imageErrors: [
        {
          icon: '',
          banner: '',
        }
      ],
    }
  },
  methods: {
    handleRemoveImage(type) {
      if (type === 'icon') {
        this.topics[this.index].icon = ""
      } else if (type === 'banner') {
        this.topics[this.index].page.image = ""
      }
    },
    handleDrop(event, type) {
      event.preventDefault();
      this.imageType = type;
      this.uploadImage(event.dataTransfer.files[0]);
    },
    handleFileChange(event, type) {
      this.imageType = type;
      this.uploadImage(event.target.files[0]);
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + this.imageType,
        scale: 0.6,
      });
      let data = new FormData();
      data.append("profileImage", event);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (this.imageType === "icon") {
            this.topics[this.index].icon = res.data.Location;
          } else if (this.imageType === "banner") {
            this.topics[this.index].page.image = res.data.Location;
          }
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    clickUploadImage(refName) {
      this.$refs[refName].click();
    },
    async validatePartnerForm() {
      if (this.$refs.refPartner) {
        return Promise.all(
          this.$refs.refPartner.map(async component => {
            if (component.handleFormValidation) {
              return await component.handleFormValidation();
            }
            return false
          })
        )
      }
    },
    async handleFormValidation() {
      let results = []
      const isValid = await this.$validator.validateAll("topic");
      const subHeader = this.$refs.refSubheader
      const header = this.$refs.refHeader
      const partnersForm = await this.validatePartnerForm()

      // form checker
      results.push(isValid)

      // banner image checker
      if (!this.topics[this.index].page.image) {
        this.imageErrors[0].banner = "error"
        results.push(false)
      }

      // icon checker
      if (!this.topics[this.index].icon) {
        this.imageErrors[0].icon = "error"
        results.push(false)
      }

      // sub header textarea checker
      if (subHeader) {
        const { valid } = await subHeader.validate()
        results.push(valid)
      }

      // header textarea checker
      if (header) {
        const { valid } = await header.validate()
        results.push(valid)
      }


      // partners form checker
      if (partnersForm) {
        results = [
          ...results,
          ...partnersForm
        ]
      }

      return !results.some(bool => !bool)
    },
    addPartner() {
      this.topics[this.index].page.partners.push({
        icon: "",
        image: "",
        label: "",
        value: "",
        description: "",
        learnMore: "",
        benefits: "",
        contacts: [],
      })
    },
    removePartner(index) {
      this.topics[this.index].page.partners.splice(index, 1)
    }
  },
}
</script>

<style lang="scss">
.customHeight {
  height: 55dvh;
}
</style>